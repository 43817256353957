.home_main_banner .carousel {
    display: flex;
    flex-direction: column;

    .control-dots {
        position: unset;
        order: 2;
    }

    .carousel-status {
        display: none;
    }

    .control-arrow {
        display: none;
    }

    .control-dots {
        margin: 0;

        .dot {
            width: 12px;
            height: 12px;
            margin: 0 4px;
            box-shadow: none;
            border: 1px solid #000;

            &.selected {
                background-color: black;
            }
        }
    }
}