.modal {
    &.bmi-popup {
        .modal-dialog {
            @apply flex items-center justify-center;

            .modal-content {
                @apply max-w-[640px] h-auto m-auto bg-transparent rounded-3xl py-6 p-16 w-[90%] max-md:p-6;
                background-color: transparent;
                background-image: linear-gradient(180deg, rgba(221, 225, 255, 1) 0%, rgba(232, 231, 231, 1) 52%, rgba(245, 214, 231, 1) 100%);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;

                .modal-header {
                    @apply border-none pb-8 p-0 max-md:pb-0;
                    .modal-title {
                        @apply text-xl sr-only;
                    }

                    .btn-close {
                        @apply text-white inline-block w-[20px] h-[20px] p-0 m-auto hover:border-none hover:bg-transparent focus:shadow-none absolute top-4 right-4 opacity-100;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.317' height='29.317' viewBox='0 0 29.317 29.317'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-width='2'%3E%3Cpath data-name='Line 25' d='M21.991 7.326 7.325 21.992'/%3E%3Cpath data-name='Line 24' d='M21.984 21.984 7.333 7.333'/%3E%3C/g%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat no-repeat;
                        background-position: center center;
                        background-size: contain;
                    }
                }

                .modal-body {

                    @apply p-0;

                    h2 {
                        @apply border-none pb-8 px-0 text-xl font-medium;
                    }

                    .gender {
                        @apply col-span-2 grid grid-cols-2 gap-6 max-w-[640px];
                        .field {
                            input {
                                @apply sr-only;

                                &:checked + label {
                                    @apply bg-[#41BDDE] border-[#41BDDE] text-[#41BDDE];
                                    --tw-bg-opacity: .1;
                                }
                            }

                            label {
                                @apply flex w-full gap-6 px-8 border bg-white rounded-full h-[49px] items-center max-md:justify-center font-normal text-sm cursor-pointer max-md:px-4 max-md:gap-4;
                            }
                        }
                    }

                    .fieldset {
                        @apply flex flex-wrap mt-6;
                        .field {
                            @apply bg-white w-full rounded-2xl p-3 mb-6 text-center;

                            label {
                                @apply block text-center text-xs font-light;
                            }

                            span {
                                @apply py-1 font-bold px-4 bg-white rounded-full drop-shadow inline-block mb-2 mt-1;
                            }

                            input[type='range'] {
                                @apply w-full appearance-none h-[10px] bg-[#ddd] rounded-full;
                                -webkit-appearance: none;

                                &::-webkit-slider-thumb {
                                    -webkit-appearance: none;
                                    appearance: none;
                                    width: 15px;
                                    height: 15px;
                                    background: #41BDDE;
                                    border-radius: 50%;
                                    cursor: pointer;
                                }

                                &::-moz-range-thumb {
                                    width: 15px;
                                    height: 15px;
                                    background: #41BDDE;
                                    border: none;
                                    border-radius: 50%;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .actions {
                        @apply flex justify-end items-center mt-4 gap-2;
                        button {
                            @apply h-auto w-auto p-2 font-medium border-none text-sm focus:outline-none focus:border-none focus:shadow-none mx-auto px-4;
                        }
                    }

                    .btn {
                        @apply h-[36px] w-full text-sm text-white;
                    }
                }

                .bmi-result {
                    h2 {
                        @apply pb-2;
                    }

                    .bmi-result-details {
                        @apply bg-white p-4 rounded-xl;
                    }

                    .bmi-status {
                        [class*="bmi-progress"] {
                            @apply w-full h-[18px] rounded-md bg-green-200;
                        }
                        .status {
                            span {
                                @apply font-bold;
                                &.Normal {
                                    @apply text-[#54d171];
                                }
                                &.Under.Weight {
                                    @apply text-[#ffdc1d];
                                }
                                &.Over.Weight {
                                    @apply text-[#ffa740];
                                }
                                &.Obese {
                                    @apply text-[#ef3737];
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
