.user_engagements {
    &.items {

        .item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            a {
                color: black;
                text-decoration: none;
                gap: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                height: 100%;

                .hover_image {
                    display: none;
                }

                &:hover {
                    img {
                        display: none;

                        &.hover_image {
                            display: block;
                        }
                    }
                }

                span {
                    display: block;
                    text-align: center;
                    height: 30px;
                }
            }
        }
    }
}

@media (max-width: 575px) {
    .user_engagements {

        &.items {
            .item {

                a {

                    span {
                        font-size: 10px;
                    }
                }
            }
        }
    }

}