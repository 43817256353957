.bottom-tab-nav {
    .nav {
        //background: transparent linear-gradient(256deg, #F0F2FF 0%, #D9D9D9 50%, #FFF2F9 100%) 0 0 no-repeat padding-box;
        background: linear-gradient(to bottom right, rgba(240, 242, 255, 0.63) 0%, rgba(217, 217, 217, 0.63) 50%, rgba(255, 242, 249, 0.63) 100%);
        backdrop-filter: blur(18px);
        @apply rounded-full;

        .nav-item {
            a {
                @apply w-16 h-16 rounded-full flex items-center justify-center p-0 text-[#575756];

                .label {
                    @apply hidden text-xs;
                }

                &.active {
                    @apply text-white bg-[#41BDDE];

                    img {
                        @apply text-base invert-0;
                    }

                    .label {
                        @apply block;
                    }
                }
            }
        }
    }

    .addtocart-bottom-tab-nav {
        background: linear-gradient(to bottom right, rgba(240, 242, 255, 0.63) 0%, rgba(217, 217, 217, 0.63) 50%, rgba(255, 242, 249, 0.63) 100%);
        backdrop-filter: blur(18px);
        @apply rounded-3xl -mb-10 pb-14 flex justify-between;
    }

    .pdpaddtocart-bottom-tab-nav {
        background: linear-gradient(to bottom right, rgba(240, 242, 255, 0.63) 0%, rgba(217, 217, 217, 0.63) 50%, rgba(255, 242, 249, 0.63) 100%);
        backdrop-filter: blur(18px);
        @apply rounded-3xl -mb-10 pb-14 flex justify-between;
        .addtocart {
            @apply w-full bg-black border-black h-[50px] font-medium;
        }
    }
}