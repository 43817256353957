.upload-precription-items {
    .upload-precription-item {
        @apply rounded-[50px] max-md:rounded-2xl;

        label {
            span {
                @apply text-xs font-normal text-gray-400 text-center;
            }
        }

        .close {
            @apply absolute top-[-4px] right-[-4px] w-auto h-auto object-cover;

            button {
                @apply bg-transparent w-[36px] h-[36px] border-none text-black p-0;
            }
        }
    }
}