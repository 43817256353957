.FeaturedListPromotionalBanner {
    .banner {
        img {
            border-radius: 30px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
        }
    }

    .slick-list {
        .slick-track {
            .carousel-item {
                max-width: 205px;
            }
        }
    }
}