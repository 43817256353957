.filter_main_wrapper {
    .accordion {
        border: none;

        .accordion-item {
            border: none;

            .accordion-header {
                border: none;

                button {
                    border: none;
                    box-shadow: none;
                    padding: 0;
                    height: auto;
                    font-size: 15px;
                    font-weight: 500;
                    color: #000000;

                    &:hover,
                    &:focus {
                        border: none;
                        outline: none;
                        background-color: transparent;
                    }

                    &:not(.collapsed) {
                        background-color: transparent;
                        border: none;
                    }
                }
            }

            .accordion-body {
                padding: 10px 0 0;

                .list-group {
                    padding: 0 10px;
                    gap: 6px;

                    .list-group-item {
                        padding: 0;
                        border: none;
                        display: flex;
                        gap: 10px;
                        align-items: center;
                        justify-content: start;

                        input[type="radio"] {
                            appearance: none;
                            border: none;
                            display: flex;
                            position: relative;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            &::before {
                                content: '';
                                display: inline-block;
                                width: 20px;
                                height: 20px;
                                border: 1px solid #707070;
                                border-radius: 50%;
                                -webkit-border-radius: 50%;
                                -moz-border-radius: 50%;
                                -ms-border-radius: 50%;
                                -o-border-radius: 50%;
                            }

                            &::after {
                                content: '';
                                display: inline-block;
                                width: 13px;
                                height: 13px;
                                background-color: #41BDDE;
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                                border-radius: 50%;
                                -webkit-border-radius: 50%;
                                -moz-border-radius: 50%;
                                -ms-border-radius: 50%;
                                -o-border-radius: 50%;
                                -webkit-transform: translate(-50%, -50%);
                                -moz-transform: translate(-50%, -50%);
                                -ms-transform: translate(-50%, -50%);
                                -o-transform: translate(-50%, -50%);
                                opacity: 0;
                                visibility: hidden;
                            }

                            &:checked {
                                &::after {
                                    opacity: 1;
                                    visibility: visible;
                                }

                                & + span {
                                    color: black;
                                }
                            }
                        }

                        span {
                            font-size: 12px;
                            font-weight: 300;
                            color: #AAAAAA;
                        }
                    }
                }
            }
        }
    }

    .multi-range-slider {
        @apply shadow-none border-none flex-col-reverse py-1;
        .bar {
            @apply items-center;
            .thumb {
                @apply w-3 h-3;
                &::before {
                    @apply shadow-none border-none bg-[#3EC0DF] w-3 h-3 m-0 left-0 top-0;
                }
            }

            .bar-left {
                @apply h-[3px] p-0 bg-[#3EC0DF] shadow-none border-none;
            }

            .bar-right {
                @apply h-[3px] bg-[#3EC0DF] shadow-none border-none;
            }

            .bar-inner {
                @apply h-[3px] bg-[#3EC0DF] shadow-none border-none;
            }
        }

        .labels {
            @apply mt-0 mb-2;
            .label {
                @apply border border-black text-sm px-6 rounded-full w-full max-w-[40%];
            }
        }

    }
}

/* filter popup */
.filter-popup {
    .modal-dialog {
        @apply flex items-center justify-center;
        .modal-header {
            @apply border-none p-0;
            .modal-title {
                @apply text-xl;
            }

            .btn-close {
                @apply text-white inline-block w-[20px] h-[20px] p-0 m-auto hover:border-none hover:bg-transparent focus:shadow-none absolute top-4 right-4 opacity-100;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.317' height='29.317' viewBox='0 0 29.317 29.317'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-width='2'%3E%3Cpath data-name='Line 25' d='M21.991 7.326 7.325 21.992'/%3E%3Cpath data-name='Line 24' d='M21.984 21.984 7.333 7.333'/%3E%3C/g%3E%3C/svg%3E%0A");
                background-repeat: no-repeat no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }

        .modal-content {
            @apply max-w-[640px] h-auto m-auto bg-transparent rounded-3xl p-6 w-[90%];
            background: transparent linear-gradient(to bottom right, #f0f2ff 0%, #d9d9d9 50%, #fff2f9 100%) no-repeat center;
            background-size: cover;

            .modal-body {
                @apply p-0;
                p {
                    @apply text-center font-medium;
                }

                .customer-review-form {
                    @apply grid grid-cols-2 gap-4;
                    label {
                        @apply sr-only;
                    }

                    input {
                        @apply rounded-full h-[49px] px-6 text-base;
                    }

                    .star-rating {
                        @apply flex items-center gap-6 pl-4 col-span-2;
                        .style-module_starRatingWrap__q-lJC {
                            @apply bg-white rounded-full py-1 px-4;
                        }

                        .react-simple-star-rating {

                            .empty-icons {
                                svg {
                                    @apply inline-block w-5 h-5;
                                }
                            }

                            .filled-icons {
                                svg {
                                    @apply inline-block w-5 h-5 text-[#575756];
                                }
                            }
                        }
                    }

                    .feedback {
                        @apply col-span-2;
                        .label {
                            @apply font-medium text-2xl inline-block mb-6;
                        }

                        textarea {
                            @apply w-full rounded-2xl p-4 h-[180px] resize-none;
                        }
                    }
                }

                .actions {
                    @apply flex justify-end items-center gap-2;
                    button {
                        @apply h-auto w-auto py-1 font-normal border-none text-base focus:outline-none focus:border-none focus:shadow-none mx-auto px-4 bg-transparent text-[#AAAAAA];
                        &.active {
                            @apply rounded-none border-0 border-b-[3px] border-btn-color border-solid text-black;
                        }
                    }
                }

                .sortby-content {
                    @apply border p-4 rounded-2xl;
                    input[type='radio'] {
                        @apply appearance-none w-4 h-4 bg-white rounded-full checked:bg-btn-color checked:border-2 checked:border-white;
                    }
                }

                .filter-content {
                    @apply border p-4 rounded-2xl flex gap-3;
                    input[type='radio'] {
                        @apply appearance-none w-4 h-4 bg-white rounded-full checked:bg-btn-color checked:border-2 checked:border-white;
                    }

                    .items {
                        @apply max-h-[208px] overflow-y-auto overflow-x-hidden border-l pl-3 w-full pr-1.5;
                        &::-webkit-scrollbar {
                            width: 6px;
                            @apply rounded-3xl ;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: #BFBFBF;
                            @apply rounded-3xl;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            background: #333;
                        }

                        .item {
                            @apply text-sm;

                            input {
                                @apply appearance-none w-4 h-4 bg-white rounded-full checked:bg-btn-color checked:border-2 checked:border-white;
                            }

                            .multi-range-slider {
                                @apply shadow-none border-none flex-col-reverse py-1;
                                .bar {
                                    @apply items-center;
                                    .thumb {
                                        @apply w-3 h-3 bg-transparent;
                                        &::before {
                                            @apply shadow-none border-none bg-[#3EC0DF] w-3 h-3 m-0 left-0 top-0;
                                        }
                                    }

                                    .bar-left {
                                        @apply h-[3px] p-0 bg-[#3EC0DF] shadow-none border-none;
                                    }

                                    .bar-right {
                                        @apply h-[3px] bg-[#3EC0DF] shadow-none border-none;
                                    }

                                    .bar-inner {
                                        @apply h-[3px] bg-[#3EC0DF] shadow-none border-none;
                                    }
                                }

                                .labels {
                                    @apply mt-0 mb-2;
                                    .label {
                                        @apply border border-black text-sm px-2 rounded-full w-full max-w-[40%] justify-center;
                                    }
                                }

                            }
                        }
                    }

                    button {
                        @apply bg-transparent text-black border-transparent px-3;

                        &.active {
                            @apply bg-white border border-white;
                        }
                    }
                }
            }
        }
    }
}