.cotegory_listing {
    ul {
        @apply flex md:gap-x-6 max-md:gap-x-3 px-0 max-md:flex-wrap max-md:grid grid-cols-3 gap-3;

        li {
            width: 100%;

            a {
                @apply inline-block;
                img {
                    width: 100%;
                    border-radius: 30px;
                    -webkit-border-radius: 30px;
                    -moz-border-radius: 30px;
                    -ms-border-radius: 30px;
                    -o-border-radius: 30px;
                    @apply min-w-[110px] h-full max-md:rounded-xl;
                }
            }
        }
    }

    p {
        @apply text-center mt-1;
    }

    .slick-slider .slick-track {
        @apply mx-auto;
    }

    .carousel-item {
        @apply max-md:max-w-[115px];
        p {
            @apply max-md:text-xs font-normal;
        }

        img {
            @apply rounded-[15px];
        }
    }
}