.SingleBanner {
    //padding-bottom: 30px;

    .container {
        .col {
            img {
                border-radius: 30px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                width: 100%;
                @apply max-md:rounded-lg;
            }
        }
    }
}