@media (max-width: 767px) {
    .order-summary {
        background: url('../../assets/images/order_complete_background_1.png') no-repeat bottom center;
        background-size: cover;
        @apply p-8 max-w-[400px] mx-auto;
    }
}

/* mob my order details wrapper */
.mob {
    &.my-order-details-wrapper {
        .my-order-details-container {
            .order-id-wrapper {
                @apply hidden;
            }

            .order-details {
                @apply max-h-none px-0 mt-0 py-4;
                .order-details-info {
                    @apply flex-wrap;

                    .delivery-Address {
                        @apply max-w-full w-full px-7;
                        h3 {
                            @apply text-base font-medium text-[#6C6C6C];
                        }

                        p {
                            @apply text-sm text-black font-normal;
                        }
                    }

                    .order-summary {
                        @apply w-full px-7 mt-2;
                    }


                }

                .order-items {
                    @apply border-t px-7;
                    .order-item {
                        @apply justify-start gap-4;
                        .ordered-product-image-detail {
                            @apply max-w-[3.5rem] max-h-[3.5rem]
                        }

                        p {
                            @apply text-sm;
                        }
                    }
                }

                .order-status-bottom {
                    @apply px-7;
                }
            }
        }
    }
}

.select-return-products {
    .modal-header {
        .btn-close {
            @apply text-white inline-block w-[30px] h-[30px] p-0 ml-auto hover:border-none focus:shadow-none opacity-100;
            background: url("../../assets/images/icons/icon_close.png") no-repeat center / contain;
        }
    }

    .modal-content {
        background-image: linear-gradient(to right, rgba(221, 225, 255, .75) 0%, rgba(232, 231, 231, .75) 52%, rgba(245, 214, 231, .75) 100%);
        backdrop-filter: blur(10px);
        @apply md:p-6;
    }

    .order-items {
        .order-item {
            @apply first-of-type:border-b mb-4;
            .add-photo {
                @apply relative text-right inline-flex justify-end w-auto;
                button {
                    @apply px-0 focus:border-none hover:border-none flex items-center gap-2;
                }
                #file-upload-product-pic {
                    @apply absolute top-0 left-0 w-auto cursor-pointer hidden;
                }
            }
            .comment {
                background: linear-gradient(to bottom right, rgba(240, 242, 255, 0.63) 0%, rgba(255, 242, 249, 0.63) 100%);
                @apply p-3 resize-none;
                &::placeholder {
                    @apply text-xs text-gray-400;
                }
            }
        }
    }

    .modal-dialog {
        @apply md:h-full md:flex md:items-center md:mt-0;
    }
}