.duelBanner {
    //padding-bottom: 30px;

    .container {
        .col {
            @apply px-0;
            img {
                width: 100%;
                max-width: 100%;
                border-radius: 30px;
                -webkit-border-radius: 30px;
                -moz-border-radius: 30px;
                -ms-border-radius: 30px;
                -o-border-radius: 30px;
                @apply max-md:rounded-lg;
            }
        }
    }
}