.loading-wrapper {
    @apply bg-white fixed z-[9999999] w-full h-full flex items-center justify-center top-0;
    .loading {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        .loader {
            border: 8px solid #f3f3f3;
            border-radius: 50%;
            border-top: 8px solid #A020F0;
            border-right: 8px solid #87CEEB;
            border-bottom: 8px solid #A020F0;
            border-left: 8px solid #87CEEB;
            width: 60px !important;
            height: 60px !important;
            -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
        }
    }
}

.payment-loader {
    @apply bg-black bg-opacity-20 fixed z-[9999999] w-full h-full flex items-center justify-center top-0;
    .loading {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);

        .loader {
            border: 8px solid #f3f3f3;
            border-radius: 50%;
            border-top: 8px solid #A020F0;
            border-right: 8px solid #87CEEB;
            border-bottom: 8px solid #A020F0;
            border-left: 8px solid #87CEEB;
            width: 60px !important;
            height: 60px !important;
            -webkit-animation: spin 2s linear infinite;
            animation: spin 2s linear infinite;
        }
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg); /* Starting position (0 degrees) */
    }
    100% {
        transform: rotate(360deg); /* Ending position (360 degrees) */
    }
}

