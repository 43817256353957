.address-type {
    > label {
        > div {
            @apply inline-flex gap-2 items-center justify-center;

            .input_field {
                @apply flex w-[18px] h-[18px] justify-center items-center;
            }
        }
    }

    input[type="radio"] {
        appearance: none;
        display: inline-block;
        outline: 2px solid #000;
        outline-offset: 2px;
        background: white;
        height: 10px;
        width: 10px;
        border-radius: 50%;

        &:checked {
            background: black;
            @apply max-md:bg-btn-color outline-black;
        }
    }
}

.general-profile-info {
    @apply grid grid-cols-2 gap-6 max-w-[640px];
    .field {
        label {
            @apply mb-3 font-medium;
        }

        input {
            &[type="text"] {
                @apply rounded-full block w-full px-8 h-[49px];
            }
        }
    }

    .gender {
        @apply col-span-2 grid grid-cols-2 gap-6 max-w-[640px];
        .field {
            input {
                @apply sr-only;

                &:checked + label {
                    @apply bg-[#41BDDE] border-[#41BDDE] text-[#41BDDE];
                    --tw-bg-opacity: .1;
                }
            }

            label {
                @apply flex w-full gap-6 px-8 border bg-white rounded-full h-[49px] items-center font-normal text-sm cursor-pointer;
            }
        }
    }

    .btn {
        @apply inline-block w-auto h-auto px-6 py-1 font-medium text-sm hover:text-white;
    }
}

.address-items {
    @apply overflow-y-auto;
    max-height: calc(360px - (28px + 0.875rem + 0.5rem));

    &::-webkit-scrollbar {
        width: 8px;
        @apply rounded-3xl;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        @apply rounded-3xl;
    }

    &::-webkit-scrollbar-thumb {
        background: #ccc;
        @apply rounded-3xl;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    .address-item {
        @apply bg-white rounded-2xl mb-6 p-6 flex justify-between relative;

        .address-type {
            @apply absolute right-6 px-2 font-medium text-xl;
        }

        .name, .address, .phone {
            @apply flex gap-4;
            label {
                @apply text-[#D1D1D1] min-w-[65px] text-right font-light;
            }

            span {
                @apply font-light;
            }
        }

        .actions {
            @apply inline-flex h-auto items-center mt-auto;
            .btn {
                @apply bg-transparent text-black font-medium h-auto w-auto px-2 border-none rounded-none text-[18px];
                &:not(:first-child) {
                    @apply border-0 border-l border-solid border-black;
                }
            }
        }

        &:last-child {
            @apply mb-0;
        }
    }
}

.modal-backdrop {
    --bs-backdrop-opacity: .6;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
}

.modal {
    &.delete-address {
        .modal-dialog {
            @apply flex items-center justify-center;
            .modal-header {
                @apply border-none pb-16 max-md:pb-6;
                .modal-title {
                    @apply sr-only;
                }

                .btn-close {
                    @apply text-white inline-block w-[30px] h-[30px] p-0 m-auto hover:border-none focus:shadow-none opacity-100;
                    background: url("../../assets/images/icons/icon_close.png") no-repeat center / contain;
                }
            }

            .modal-content {
                @apply max-w-[640px] h-auto m-auto rounded-3xl bg-transparent max-md:w-[90%];

                .modal-body {
                    @apply rounded-3xl;
                    background-color: transparent;
                    background-image: linear-gradient(180deg, rgba(221, 225, 255, 1) 0%, rgba(232, 231, 231, 1) 52%, rgba(245, 214, 231, 1) 100%);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;

                    p {
                        @apply text-center font-medium;
                    }

                    .actions {
                        @apply flex justify-end items-center pr-12 mt-4 gap-2 max-md:pr-2;
                        button {
                            @apply bg-transparent h-auto w-auto p-2 font-medium border-none text-sm text-[#61adef] hover:bg-[#5aabef0d] rounded-md focus:outline-none focus:border-none focus:shadow-none;
                        }
                    }
                }
            }
        }
    }

    &.customer-review {
        .modal-dialog {
            @apply flex items-center justify-center;
            .modal-header {
                @apply border-none pb-8;
                .modal-title {
                    @apply text-xl;
                }

                .btn-close {
                    @apply text-white inline-block w-[20px] h-[20px] p-0 m-auto hover:border-none hover:bg-transparent focus:shadow-none absolute top-4 right-4 opacity-100;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.317' height='29.317' viewBox='0 0 29.317 29.317'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-width='2'%3E%3Cpath data-name='Line 25' d='M21.991 7.326 7.325 21.992'/%3E%3Cpath data-name='Line 24' d='M21.984 21.984 7.333 7.333'/%3E%3C/g%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat no-repeat;
                    background-position: center center;
                    background-size: contain;
                }
            }

            .modal-content {
                @apply max-w-[640px] h-auto m-auto bg-transparent rounded-3xl p-16;
                background-color: transparent;
                background-image: linear-gradient(180deg, rgba(221, 225, 255, 1) 0%, rgba(232, 231, 231, 1) 52%, rgba(245, 214, 231, 1) 100%);
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;

                .modal-body {

                    p {
                        @apply text-center font-medium;
                    }

                    .customer-review-form {
                        @apply grid grid-cols-2 gap-4;
                        label {
                            @apply sr-only;
                        }

                        input {
                            @apply rounded-full h-[49px] px-6 text-base;
                        }

                        .star-rating {
                            @apply flex items-center gap-6 pl-4 col-span-2;
                            .style-module_starRatingWrap__q-lJC {
                                @apply bg-white rounded-full py-1 px-4;
                            }

                            .react-simple-star-rating {

                                .empty-icons {
                                    svg {
                                        @apply inline-block w-5 h-5;
                                    }
                                }

                                .filled-icons {
                                    svg {
                                        @apply inline-block w-5 h-5 text-[#575756];
                                    }
                                }
                            }
                        }

                        .feedback {
                            @apply col-span-2;
                            .label {
                                @apply font-medium text-2xl inline-block mb-6;
                            }

                            textarea {
                                @apply w-full rounded-2xl p-4 h-[180px] resize-none;
                            }
                        }
                    }

                    .actions {
                        @apply flex justify-end items-center mt-4 gap-2;
                        button {
                            @apply h-auto w-auto p-2 font-medium border-none text-sm focus:outline-none focus:border-none focus:shadow-none mx-auto px-4;
                        }
                    }
                }
            }
        }
    }

    &.select-payment {
        .modal-dialog {
            @apply flex items-center justify-center;
            .modal-header {
                @apply border-none pb-16;
                .modal-title {
                    @apply sr-only;
                }

                .btn-close {
                    @apply text-white inline-block w-[30px] h-[30px] p-0 m-auto hover:border-none focus:shadow-none opacity-100;
                    background: url("../../assets/images/icons/icon_close.png") no-repeat center / contain;
                }
            }

            .modal-content {
                @apply max-w-[640px] h-auto m-auto rounded-3xl bg-transparent max-md:max-w-[90%];

                .modal-body {
                    @apply rounded-3xl p-8 pb-16;
                    background-color: transparent;
                    background-image: linear-gradient(180deg, rgba(221, 225, 255, 1) 0%, rgba(232, 231, 231, 1) 52%, rgba(245, 214, 231, 1) 100%);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;

                    h2 {
                        @apply font-medium text-center mb-3;
                    }

                    .fieldset {
                        @apply flex flex-wrap gap-6;
                        .field {
                            @apply flex w-full gap-6 items-center;
                            input[type="radio"] {
                                appearance: none;
                                display: inline-block;
                                outline: 2px solid #666;
                                outline-offset: 2px;
                                background: transparent;
                                height: 10px;
                                width: 10px;
                                border-radius: 50%;
                                @apply cursor-pointer;

                                &:checked {
                                    outline: 2px solid #41BDDE;
                                    background: #41BDDE;
                                }
                            }

                            label {
                                @apply cursor-pointer text-sm;
                            }
                        }
                    }

                    .actions {
                        @apply flex justify-end items-center px-4 mt-20 gap-2 max-md:mt-10;
                        button {
                            @apply h-[36px] w-full p-2 px-6 font-medium border-none text-sm rounded-full focus:outline-none focus:border-none focus:shadow-none;
                        }
                    }
                }
            }
        }
    }

    &.settings-popup {
        .modal-dialog {
            @apply flex items-center justify-center;
            .modal-header {
                @apply border-none p-0;
                .modal-title {
                    @apply sr-only;
                }

                .btn-close {
                    @apply text-white inline-block w-[20px] h-[20px] p-0 m-auto hover:border-none hover:bg-transparent focus:shadow-none absolute top-3 right-3 opacity-100 z-50;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.317' height='29.317' viewBox='0 0 29.317 29.317'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-width='2'%3E%3Cpath data-name='Line 25' d='M21.991 7.326 7.325 21.992'/%3E%3Cpath data-name='Line 24' d='M21.984 21.984 7.333 7.333'/%3E%3C/g%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat no-repeat;
                    background-position: center center;
                    background-size: contain;
                }
            }

            .modal-content {
                @apply max-w-[640px] h-auto m-auto rounded-3xl bg-transparent max-md:w-[90%];

                .modal-body {
                    @apply rounded-3xl p-8;
                    background-color: transparent;
                    background-image: linear-gradient(180deg, rgba(221, 225, 255, 1) 0%, rgba(232, 231, 231, 1) 52%, rgba(245, 214, 231, 1) 100%);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;

                    ul {
                        @apply pr-2;
                        &::-webkit-scrollbar {
                            width: 8px;
                            @apply rounded-3xl;
                        }

                        &::-webkit-scrollbar-track {
                            background: #f1f1f1;
                            @apply rounded-3xl;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: #000000;
                            @apply rounded-3xl;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            background: #555;
                        }
                    }

                    h2 {
                        @apply font-medium text-center mb-3;
                    }

                    .fieldset {
                        @apply flex flex-wrap gap-6;
                        .field {
                            @apply flex w-full gap-6 items-center;
                            input[type="radio"] {
                                appearance: none;
                                display: inline-block;
                                outline: 2px solid #666;
                                outline-offset: 2px;
                                background: transparent;
                                height: 10px;
                                width: 10px;
                                border-radius: 50%;
                                @apply cursor-pointer;

                                &:checked {
                                    outline: 2px solid #41BDDE;
                                    background: #41BDDE;
                                }
                            }

                            label {
                                @apply cursor-pointer text-sm;
                            }
                        }
                    }

                    .actions {
                        @apply flex justify-end items-center px-4 mt-20 gap-2 max-md:mt-10;
                        button {
                            @apply h-[36px] w-full p-2 px-6 font-medium border-none text-sm rounded-full focus:outline-none focus:border-none focus:shadow-none;
                        }
                    }
                }
            }
        }
    }

    &.my-interests-popup {
        .modal-dialog {
            @apply flex items-center justify-center;
            .modal-header {
                @apply border-none p-0;
                .modal-title {
                    @apply sr-only;
                }

                .btn-close {
                    @apply text-white inline-block w-[20px] h-[20px] p-0 m-auto hover:border-none hover:bg-transparent focus:shadow-none absolute top-3 right-3 opacity-100 z-50;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.317' height='29.317' viewBox='0 0 29.317 29.317'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-width='2'%3E%3Cpath data-name='Line 25' d='M21.991 7.326 7.325 21.992'/%3E%3Cpath data-name='Line 24' d='M21.984 21.984 7.333 7.333'/%3E%3C/g%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat no-repeat;
                    background-position: center center;
                    background-size: contain;
                }
            }

            .modal-content {
                @apply max-w-[640px] h-auto m-auto rounded-xl bg-transparent max-md:w-[90%];

                .modal-body {
                    @apply rounded-xl p-8;
                    background-color: transparent;
                    background-image: linear-gradient(180deg, rgba(221, 225, 255, 1) 0%, rgba(232, 231, 231, 1) 52%, rgba(245, 214, 231, 1) 100%);
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: cover;

                    ul {
                        @apply pr-2;
                        &::-webkit-scrollbar {
                            width: 8px;
                            @apply rounded-3xl;
                        }

                        &::-webkit-scrollbar-track {
                            background: #f1f1f1;
                            @apply rounded-3xl;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: #000000;
                            @apply rounded-3xl;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            background: #555;
                        }
                    }

                    h2 {
                        @apply font-medium text-center mb-8;
                    }

                    .fieldset {
                        @apply flex flex-wrap gap-6;
                        .field {
                            @apply flex w-full gap-6 items-center;
                            input[type="radio"] {
                                appearance: none;
                                display: inline-block;
                                outline: 2px solid #666;
                                outline-offset: 2px;
                                background: transparent;
                                height: 10px;
                                width: 10px;
                                border-radius: 50%;
                                @apply cursor-pointer;

                                &:checked {
                                    outline: 2px solid #41BDDE;
                                    background: #41BDDE;
                                }
                            }

                            label {
                                @apply cursor-pointer text-sm;
                            }
                        }
                    }

                    .actions {
                        @apply flex justify-end items-center px-4 mt-20 gap-2 max-md:mt-10;
                        button {
                            @apply h-[36px] w-full p-2 px-6 font-medium border-none text-sm rounded-full focus:outline-none focus:border-none focus:shadow-none;
                        }
                    }
                }

                .my-interests-details {
                    .other-interests {
                        @apply mt-8;

                        .other-interests-items {
                            @apply grid grid-cols-3 gap-4;
                            .other-interests-item {
                                @apply mr-0 opacity-50 relative;
                                .image {
                                    @apply w-full h-full;
                                    &:before {
                                        content: '';
                                        background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z' style='fill:%23ffba1d'/%3E%3C/svg%3E%0A");
                                        background-repeat: no-repeat no-repeat;
                                        background-position: center center;
                                        background-size: calc(100% + 3px);
                                        @apply w-6 h-6 rounded-full bg-black absolute left-auto right-0 z-10 border-white hidden;
                                    }
                                }
                                &.active {
                                    @apply opacity-100;
                                    .image {
                                        @apply before:inline-block;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.my-order-details {
    .block-tabs-order {
        @apply bg-transparent h-[38px] w-auto text-black border-0 rounded-none;
        &.active {
            @apply border-b-4 text-2xl;
        }
    }

    .ordered-items {
        @apply mt-6 max-h-[360px] overflow-y-auto;

        &::-webkit-scrollbar {
            width: 8px;
            @apply rounded-3xl;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            @apply rounded-3xl;
        }

        &::-webkit-scrollbar-thumb {
            background: #ccc;
            @apply rounded-3xl;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .ordered-item {
            @apply mb-6;
            .ordered-product-details {
                @apply flex items-center p-4 bg-white rounded-2xl;

                .ordered-product-detail-info {
                    @apply w-1/3 border-r-2 border-black;
                }

                .ordered-product-image-listing {
                    @apply w-1/3 flex overflow-y-auto;
                    &::-webkit-scrollbar {
                        height: 8px;
                        @apply rounded-3xl;
                    }

                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                        @apply rounded-3xl;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #ccc;
                        @apply rounded-3xl;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background: #555;
                    }

                    .ordered-product-image {
                        img {
                            @apply ml-3 min-w-[80px] w-[80px] h-auto;
                        }
                    }
                }

                .ordered-product-image {
                    img {
                        @apply ml-3 min-w-[80px] w-[80px] h-auto;
                    }
                }

                a {
                    @apply ml-auto w-1/3;
                    button {
                        @apply bg-black font-normal border-black h-auto py-2 px-8 min-w-[260px] text-base;
                    }
                }
            }

            &:last-child {
                @apply mb-0;
            }
        }
    }
}

.my-interests-details {
    .your-interests-items {
        @apply flex flex-wrap;
        .your-interests-item {
            @apply inline-block cursor-pointer;
            span {
                @apply inline-block py-1 px-6 border border-[#707070] rounded-full mr-3 bg-white;
            }
        }
    }

    .other-interests {
        @apply mt-10;
        .other-interests-items {
            @apply flex flex-wrap items-center justify-start;
            .other-interests-item {
                @apply flex flex-col items-center justify-center gap-2 mr-[45px] cursor-pointer;
                .image {
                    @apply inline-block w-[95px] h-[95px] rounded-full overflow-x-hidden;
                }

                .label {
                    @apply text-sm;
                }
            }
        }
    }
}

.notifications-details {
    .items {
        @apply max-h-[360px] overflow-y-auto;

        &::-webkit-scrollbar {
            width: 8px;
            @apply rounded-3xl;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            @apply rounded-3xl;
        }

        &::-webkit-scrollbar-thumb {
            background: #ccc;
            @apply rounded-3xl;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .item {
            @apply p-6 bg-white rounded-2xl mb-6;

            &:last-child {
                @apply mb-0;
            }

            h2 {
                @apply text-3xl font-medium mb-4;
            }

            span {
                @apply font-light;
            }
        }
    }
}

.my-order-details-wrapper {
    .my-order-details-container {
        .order-id-wrapper {
            @apply flex;
            .order-id {
                @apply inline-block;
            }
        }

        .order-details {
            @apply bg-white rounded-[30px] p-8 mt-10 max-h-[300px] overflow-y-auto;

            &::-webkit-scrollbar {
                width: 8px;
                @apply rounded-3xl;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
                @apply rounded-3xl;
            }

            &::-webkit-scrollbar-thumb {
                background: #ccc;
                @apply rounded-3xl;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            .order-details-info {
                @apply flex justify-between items-start;
                .delivery-Address {
                    @apply w-1/2;
                    h3 {
                        @apply text-[25px] font-medium mb-2;
                    }

                    p {
                        @apply text-base font-light text-[#6C6C6C];

                        &.mobile {
                            @apply text-black font-bold text-xl mt-1;
                        }
                    }
                }

                .order-summary {
                    @apply w-1/2;
                    h3 {
                        @apply text-[25px] font-medium mb-2;
                    }

                    .fieldset {
                        .field {
                            @apply flex justify-between items-center mb-1;
                            span {
                                @apply font-light text-sm;
                            }

                            p {
                                @apply text-sm font-light;
                            }

                            &.total {
                                @apply border-t mt-5 pt-2;
                                strong {
                                    @apply text-xl font-medium;
                                }

                                p {
                                    @apply text-xl font-medium;
                                }
                            }
                        }
                    }
                }
            }

            .order-items {
                @apply pl-4 mt-8;
                .order-item {
                    @apply flex justify-start items-center w-full mb-6 gap-4;

                    .ordered-product-detail {
                        @apply flex gap-4;
                        .ordered-product-image-detail {
                            img {
                                @apply rounded-2xl;
                            }
                        }

                        .ordered-product-detail-info {
                            @apply flex flex-wrap h-full justify-between;
                            .name {
                                @apply text-base font-light;
                            }

                            .price {
                                @apply font-bold text-xl inline-block w-full mt-2;
                            }

                            button {
                                @apply ml-auto;
                            }
                        }
                    }

                    .actions {
                        @apply whitespace-nowrap pl-4 ml-auto;
                    }
                }
            }
        }

        .order-status-bottom {
            p {
                @apply py-2 px-4 text-xs rounded-full font-medium;
                &.Placed {
                    @apply bg-green-100 text-green-500;
                }

                &.Canceled {
                    @apply bg-red-100 text-red-500;
                }

                &.Delivered {
                    @apply bg-gray-100 text-gray-900;
                }
            }

            .cancleorder {
                @apply bg-transparent h-auto w-auto p-2 font-medium border-none text-sm text-[#61adef] hover:bg-[#5aabef0d] rounded-md focus:outline-none focus:border-none focus:shadow-none cursor-pointer capitalize inline-block;
            }
        }
    }

    > a {
        @apply sr-only;
    }
}

/* Edit profile mobile */
.mob-editprofile {
    form {
        .fieldset {
            @apply mt-6;
            .field {
                input {
                    @apply placeholder:text-xs placeholder:font-normal text-base autofill:bg-red-200 focus:border-btn-color;
                }
            }
        }
    }

    .gender {
        @apply col-span-2 grid grid-cols-2 gap-6 max-w-[640px];
        .field {
            input {
                @apply sr-only;

                &:checked + label {
                    @apply bg-[#41BDDE] border-[#41BDDE] text-[#41BDDE];
                    --tw-bg-opacity: .1;
                }
            }

            label {
                @apply flex w-full gap-6 px-8 border bg-white rounded-full h-[40px] items-center font-normal text-sm cursor-pointer max-md:px-4 max-md:gap-4;
            }
        }
    }
}

/* .my-order-details .ordered-items /*/
.my-order-details .ordered-items {
    @apply max-h-none overflow-y-hidden;
}

/* add address mobile */
.address-items {
    &.mob {
        @apply max-h-none mt-4;
        overflow: unset;

        .address-item {
            @apply bg-white rounded-2xl mb-4 p-4 flex justify-between relative border;

            .address-type {
                @apply absolute right-6 px-2 font-medium text-sm;
            }

            .name, .address, .phone {
                @apply flex gap-4 mb-0.5;
                label {
                    @apply text-[#D1D1D1] min-w-[65px] text-right font-light text-sm sr-only;
                }

                span {
                    @apply font-normal text-xs;
                }
            }

            .name {
                span {
                    @apply text-sm font-normal;
                }
            }

            .actions {
                @apply inline-flex h-auto items-center mb-auto mt-0 relative;
                .btn {
                    @apply bg-transparent text-black font-normal h-auto w-auto px-0 border-none rounded-none text-sm;
                    &:not(:first-child) {
                        @apply border-0 border-l border-black;
                    }

                    svg {
                        @apply hidden;
                    }
                }

                .actions-body {
                    @apply p-4 py-6 absolute top-0 right-0 z-[9991] whitespace-nowrap flex flex-col items-start gap-6 bg-white w-[180px];
                    box-shadow: 7px 7px 8px -5px rgba(0, 0, 0, 0.4);
                }
            }

            &:last-child {
                @apply mb-0;
            }

            &.default-address {
                box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.1);

                .actions {
                    .btn {
                        &.setasdefault {
                            svg {
                                @apply block;
                            }
                        }
                    }
                }
            }
        }
    }
}