.modal-inside {
    font-size: 12px;
}

.modal-inside > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}

.modal-inside > .content {
    width: 100%;
    padding: 10px 5px;
}

.modal-inside > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.otp-input-custom-class {
    padding-left: 5px !important;
    padding-right: 5px !important;
    width: 5em !important;
}

.login-popup {
    &-overlay {
        background: rgba(255, 255, 255, 0.7);
        opacity: 1;
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);

        .modal-inside > .close {
            color: black;
            cursor: pointer;
            position: absolute;
            display: block;
            padding: 2px 5px;
            line-height: 20px;
            right: -10px;
            top: -10px;
            font-size: 24px;
            @apply bg-transparent h-auto w-auto border-none right-4 top-4
        }

        .popup-content {
            @apply w-full max-w-[640px] rounded-[30px] py-12 px-24 max-md:max-w-[90%] max-md:px-4 max-md:py-4 max-md:shadow-none;
            //box-shadow: inset 0 3px 6px #00000029;
            background-image: linear-gradient(180deg, rgba(221, 225, 255, 1) 0%, rgba(232, 231, 231, 1) 52%, rgba(245, 214, 231, 1) 100%);
            background-repeat: no-repeat no-repeat;
            background-position: center center;
            background-size: cover;

            .modal-inside {
                .header {
                    @apply hidden;
                }

                .content {

                }

                .otp-verification {
                    > div {
                        @apply justify-center gap-2;

                        > .otp-input-custom-class {
                            @apply rounded-[25px] border border-[#E2E2E2] text-[#000] placeholder:text-[#D1D1D1] h-[80px] align-baseline;
                            width: 86px !important;
                            @apply h-[60px] rounded-xl;
                            @media (max-width: 767px) {
                                width: 60px !important;
                            }
                        }
                    }
                }

                #mobileNumberOtpFormSection {
                    form {
                        .submit-btn {
                            @apply bg-[#41BDDE] h-[45px] font-light flex items-center justify-center gap-2 px-6 max-md:h-[40px];
                            &:after {
                                content: '';
                                display: inline-block;
                                width: 14px;
                                height: 14px;
                                background: url("../../../assets/images/icons/icon_back_rounded.png") no-repeat center / cover;
                                transform: scaleX(-1);
                                filter: invert(1);
                            }
                        }
                    }

                    .resend-otp {
                        @apply border-t mt-10 flex justify-center pt-5 max-md:mt-1;
                        button {
                            @apply bg-transparent border-0 text-black p-0 text-xs h-auto;
                            &:after {
                                content: '';
                                width: 12px;
                                height: 12px;
                                display: inline-block;
                                background-image: url("data:image/svg+xml,%3Csvg width='800' height='800' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' transform='rotate(180)'%3E%3Cpath d='M13.937 4.211a1 1 0 0 1-1.126 1.653A5 5 0 1 0 15 10a1 1 0 1 1 2 0 7 7 0 1 1-3.063-5.789Z' fill='%23000'/%3E%3Cpath d='M13.539 12.506a1 1 0 1 1-1.078-1.685l3.482-2.227a1 1 0 0 1 1.077 1.685l-3.481 2.227Z' fill='%23000'/%3E%3Cpath d='M18.903 12.41a1 1 0 0 1-1.826.815l-1.508-3.38a1 1 0 1 1 1.826-.815l1.508 3.38Z' fill='%23000'/%3E%3C/svg%3E%0A");
                                background-repeat: no-repeat no-repeat;
                                background-position: center center;
                                background-size: cover;
                                margin-left: 5px;
                                filter: brightness(0);
                            }
                        }
                    }
                }
            }
        }
    }
}
