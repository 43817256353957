
.fimage {
    width: 100%;
    height: 100%;
}

.blog-items {
    @apply max-md:gap-4;
    .blog-item {
        @apply max-md:bg-light-bg rounded-xl;
        .content {
            @apply max-md:p-3;
        }

        h2,
        strong {
            @apply text-3xl font-medium max-md:block max-md:text-xl max-md:font-normal max-md:mb-3;
        }

        .update-date {
            @apply font-light mb-2 max-md:hidden;
        }

        p {
            @apply text-sm font-light;
        }

        button {
            @apply bg-black mt-5 max-md:bg-transparent max-md:h-auto max-md:text-black max-md:border-0 max-md:mt-3 max-md:mb-5 max-md:font-medium max-md:pl-3;
        }
    }
}