:root {
    --btn-clr: #41BDDE;
}

@tailwind base;
/*@tailwind components;*/
@tailwind utilities;

* {
    box-sizing: border-box;
    list-style: none;
    border-color: rgba(112, 112, 112, 0.2);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply max-md:pb-[64px] text-black;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
    padding: 0;
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

::-webkit-scrollbar {
    width: 8px;
    @apply rounded-3xl;
}

::-webkit-scrollbar-thumb {
    background: #f1f1f1;
    @apply rounded-3xl;
}

::-webkit-scrollbar-thumb:hover {
    background: #ccc;
}

a:hover {
    color: inherit;
}

button, .btn, .btn-primary {
    background-color: var(--btn-clr);
    border: 1px solid var(--btn-clr);
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    height: 54px;
    border-radius: 38px;
    -webkit-border-radius: 38px;
    -moz-border-radius: 38px;
    -ms-border-radius: 38px;
    -o-border-radius: 38px;
    @apply shadow-none;
    @apply max-md:h-[40px] max-md:text-sm py-0 px-7;
}

button:hover, .btn:hover, .btn-primary:hover {
    background-color: var(--btn-clr);
    border: 1px solid var(--btn-clr);
}

button:focus, .btn:focus, .btn-primary:focus {
    @apply shadow-none;
}

.form-control, input:is([type="text"], [type="email"]) {
    height: 54px;
    @apply leading-[52px];
    @apply max-md:h-[40px] max-md:px-3 max-md:leading-[40px];
}

.form-control:focus, input:is([type="text"], [type="email"]):focus {
    box-shadow: none;
}

.form-control::placeholder, input:is([type="text"], [type="email"])::placeholder {
    font-size: 18px;
    font-weight: 300;
    color: #D6D6D6;
}

select {
    @apply appearance-none cursor-pointer;
    background-image: url("data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 1024 1024' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat no-repeat;
    background-position: calc(100% - 25px) center;
    background-size: 14px;
}

.filter_main_wrapper {
    input {
        @apply accent-btn-color;

        &[type='radio'] {
            @apply appearance-none w-4 h-4 border-2 border-white outline outline-2 outline-[#70707033] rounded-full checked:bg-btn-color;
        }

        &[type='checkbox'] {
            @apply appearance-none w-4 h-4 border-2 rounded-sm;
            &:checked {
                @apply border-none;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 448 512' style='fill:%2341bdde'%3E%3Cpath d='M64 32C28.7 32 0 60.7 0 96v320c0 35.3 28.7 64 64 64h320c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm273 177L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z'/%3E%3C/svg%3E%0A") no-repeat no-repeat center center;
                background-size: cover;
            }
        }
    }
}


/*slick slider*/

.slick-slider {
    padding: 0 !important;
}

.slick-slider .slick-track {
    @apply ml-0 mr-0;
}

.slick-prev, .slick-next {
    @apply hover:border-none text-transparent;
    font-size: 0;
}

.slick-slider .slick-prev {
    @apply max-xl:-left-4 z-50;
}

.slick-slider .slick-next {
    @apply max-xl:-right-4 z-50;
}

.slick-prev:before {
    background-image: url("data:image/svg+xml,%3Csvg width='800' height='800' viewBox='-5 0 23 23' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.24.29.361 10.742l-.06.054a.97.97 0 0 0-.301.642v.124a.97.97 0 0 0 .3.642l.054.044L11.239 22.71a1.061 1.061 0 0 0 1.459 0 .964.964 0 0 0 0-1.402l-10.15-9.746 10.15-9.87a.964.964 0 0 0 0-1.402 1.061 1.061 0 0 0-1.459 0Z' fill='%23252528'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: cover;
    color: transparent !important;
    font-size: 0;
}

.slick-next:before {
    background-image: url("data:image/svg+xml,%3Csvg width='800' height='800' viewBox='-5 0 23 23' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m1.76.29 10.879 10.452.06.054a.97.97 0 0 1 .301.642v.124a.97.97 0 0 1-.3.642l-.054.044L1.761 22.71a1.061 1.061 0 0 1-1.459 0 .964.964 0 0 1 0-1.402l10.15-9.746-10.15-9.87a.964.964 0 0 1 0-1.402 1.061 1.061 0 0 1 1.459 0Z' fill='%23252528'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: cover;
    color: transparent !important;
    font-size: 0;
}

@media (max-width: 767px) {
    .slick-prev, .slick-next {
        display: none !important;
    }
}

/* category-accordion */
.category-accordion-wrapper .accordion {
    @apply border-none;
}

.category-accordion-wrapper .accordion__button {
    @apply bg-transparent p-0 border-none;
}

.category-accordion-wrapper .accordion__button:before {
    @apply hidden;
}

.category-accordion-wrapper .accordion__panel {
    @apply pb-0;
}

.category-accordion-wrapper .accordion__panel ul::-webkit-scrollbar {
    height: 0;
    @apply rounded-3xl;
}

.select-payment-mode {
    input[type="radio"] {
        width: 18px;
        height: 18px;
        border-radius: 9999px;
        appearance: none;
        outline: 2px solid #000000;

        &:checked {
            border: 2px solid white;
            background-color: #000000;
        }
    }
}

.checkout {
    .promo-code {
        button {
            max-width: 70px;
            padding: 0 15px !important;
        }

        input {
            width: calc(100% - 80px);
            max-width: calc(100% - 80px);
            @apply text-base;
            &::placeholder {
                @apply text-base;
            }
        }
    }
}