.modal-inside {
    font-size: 12px;
}

.modal-inside > .header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}

.modal-inside > .content {
    width: 100%;
    padding: 10px 5px;
}

.modal-inside > .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
}

.customer-review {
    .modal-dialog {
        @apply flex items-center justify-center;
        .modal-header {
            @apply border-none pb-8;
            .modal-title {
                @apply text-xl;
            }

            .btn-close {
                @apply text-white inline-block w-[20px] h-[20px] p-0 m-auto hover:border-none hover:bg-transparent focus:shadow-none absolute top-4 right-4 opacity-100;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.317' height='29.317' viewBox='0 0 29.317 29.317'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-width='2'%3E%3Cpath data-name='Line 25' d='M21.991 7.326 7.325 21.992'/%3E%3Cpath data-name='Line 24' d='M21.984 21.984 7.333 7.333'/%3E%3C/g%3E%3C/svg%3E%0A");
                background-repeat: no-repeat no-repeat;
                background-position: center center;
                background-size: contain;
            }
        }

        .modal-content {
            @apply max-w-[640px] h-auto m-auto bg-transparent rounded-3xl p-16;
            background-color: transparent;
            background-image: linear-gradient(180deg, rgba(221, 225, 255, 1) 0%, rgba(232, 231, 231, 1) 52%, rgba(245, 214, 231, 1) 100%);
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;

            .modal-body {

                p {
                    @apply text-center font-medium;
                }

                .customer-review-form {
                    @apply grid grid-cols-2 gap-4;
                    label {
                        @apply sr-only;
                    }

                    input {
                        @apply rounded-full h-[49px] px-6 text-base;
                    }

                    .star-rating {
                        @apply flex items-center gap-6 pl-4 col-span-2;
                        .style-module_starRatingWrap__q-lJC {
                            @apply bg-white rounded-full py-1 px-4;
                        }

                        .react-simple-star-rating {

                            .empty-icons {
                                svg {
                                    @apply inline-block w-5 h-5;
                                }
                            }

                            .filled-icons {
                                svg {
                                    @apply inline-block w-5 h-5 text-[#575756];
                                }
                            }
                        }
                    }

                    .feedback {
                        @apply col-span-2;
                        .label {
                            @apply font-medium text-2xl inline-block mb-6;
                        }

                        textarea {
                            @apply w-full rounded-2xl p-4 h-[180px] resize-none;
                        }
                    }
                }

                .actions {
                    @apply flex justify-end items-center mt-4 gap-2;
                    button {
                        @apply h-auto w-auto p-2 font-medium border-none text-sm focus:outline-none focus:border-none focus:shadow-none mx-auto px-4;
                    }
                }
            }
        }
    }
}

.location-map-popup {
    &-overlay {
        background: rgba(255, 255, 255, 0.7);
        opacity: 1;
        backdrop-filter: blur(30px);
        -webkit-backdrop-filter: blur(30px);

        .modal-inside > .close {
            color: black;
            cursor: pointer;
            position: absolute;
            display: block;
            padding: 2px 5px;
            line-height: 20px;
            right: -10px;
            top: -10px;
            font-size: 0;
            @apply text-white inline-block w-[20px] h-[20px] p-0 m-auto hover:border-none hover:bg-transparent focus:shadow-none absolute top-4 right-4 opacity-100 bg-transparent border-0;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29.317' height='29.317' viewBox='0 0 29.317 29.317'%3E%3Cg fill='none' stroke='%23000' stroke-linecap='round' stroke-width='2'%3E%3Cpath data-name='Line 25' d='M21.991 7.326 7.325 21.992'/%3E%3Cpath data-name='Line 24' d='M21.984 21.984 7.333 7.333'/%3E%3C/g%3E%3C/svg%3E%0A");
            background-repeat: no-repeat no-repeat;
            background-position: center center;
            background-size: contain;
            z-index: 999;
        }

        .popup-content {
            @apply w-full max-w-[640px] rounded-[30px] max-md:max-w-[90%];
            //box-shadow: inset 0 3px 6px #00000029;
            background-image: linear-gradient(180deg, rgba(221, 225, 255, 1) 0%, rgba(232, 231, 231, 1) 52%, rgba(245, 214, 231, 1) 100%);
            background-repeat: no-repeat no-repeat;
            background-position: center center;
            background-size: cover;

            .modal-inside {
                > .header {
                    @apply sr-only;
                }

                .content {
                    .header {
                        @apply text-[35px] p-0 text-left border-b-0 mb-12 max-md:text-base max-md:text-center max-md:mb-4;
                    }

                    .pac-target-input {
                        @apply h-[49px] rounded-full bg-white px-8;
                    }
                }

                .otp-verification {
                    > div {
                        @apply justify-center gap-2;

                        > .otp-input-custom-class {
                            @apply rounded-[25px] border border-[#E2E2E2] text-[#000] placeholder:text-[#D1D1D1] h-[80px] align-baseline;
                            width: 86px !important;
                        }
                    }
                }

                #mobileNumberOtpFormSection {
                    form {
                        .submit-btn {
                            @apply bg-[#41BDDE] h-[45px] font-light flex items-center justify-center gap-2 px-6;
                            &:after {
                                content: '';
                                display: inline-block;
                                width: 14px;
                                height: 14px;
                                background: url("../../../assets/images/icons/icon_back_rounded.png") no-repeat center / cover;
                                transform: scaleX(-1);
                                filter: invert(1);
                            }
                        }
                    }

                    .resend-otp {
                        @apply border-t mt-10 flex justify-center pt-5;
                        button {
                            @apply bg-transparent border-0 text-black p-0 text-xs h-auto;
                            &:after {
                                content: '';
                                width: 12px;
                                height: 12px;
                                display: inline-block;
                                background-image: url("data:image/svg+xml,%3Csvg width='800' height='800' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' transform='rotate(180)'%3E%3Cpath d='M13.937 4.211a1 1 0 0 1-1.126 1.653A5 5 0 1 0 15 10a1 1 0 1 1 2 0 7 7 0 1 1-3.063-5.789Z' fill='%23000'/%3E%3Cpath d='M13.539 12.506a1 1 0 1 1-1.078-1.685l3.482-2.227a1 1 0 0 1 1.077 1.685l-3.481 2.227Z' fill='%23000'/%3E%3Cpath d='M18.903 12.41a1 1 0 0 1-1.826.815l-1.508-3.38a1 1 0 1 1 1.826-.815l1.508 3.38Z' fill='%23000'/%3E%3C/svg%3E%0A");
                                background-repeat: no-repeat no-repeat;
                                background-position: center center;
                                background-size: cover;
                                margin-left: 5px;
                                filter: brightness(0);
                            }
                        }
                    }
                }
            }
        }
    }
}