.Collection_page_banner {
    background-color: #EFEFEF;
    padding: 20px 0;
    margin-bottom: 25px;

    img {
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
    }
}

.product_grid {
    > .row {
        @apply gap-3 md:gap-4;
        .product_item {
            padding: 0;
            .product {
                @apply bg-white border;
            }
        }
    }
}

.subcategorylist {
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
}