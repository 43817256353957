body {
    @apply overflow-x-hidden;
}

.Home {
    background-color: white;

    .home_main_banner {
        + .SingleBanner {
            @apply md:pt-[50px] pb-0;
        }
    }

    .SingleBanner {
        //@apply md:pt-12;
    }
}


@media (max-width: 768px) {
    .Home {
        .SingleBanner {
            //padding: 0 0.75rem !important;
        }

        .cotegory_listing {

            ul {
                padding: 0;
            }
        }

        .slick-prev, .slick-next {
            display: none !important;
        }
    }
}