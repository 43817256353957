.faq-accordion-wrapper {
    .accordion__button {
        @apply flex flex-row-reverse justify-between items-center transition-all ease-in-out;
        &:before {
            transform: rotate(45deg);
            @apply transition-all ease-in-out;
        }
        &[aria-expanded='true'],
        &[aria-selected='true']{
            @apply text-[#41BDDE];
            &:before {
                transform: rotate(-135deg);
            }
        }
    }

    ul {
        @apply pl-6;
        li {
            @apply list-disc max-md:text-sm max-md:leading-6;
        }
    }
}