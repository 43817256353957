.TopOffers {
    //padding-bottom: 30px;

    .carousel-item {
        @apply pr-[5px] max-md:max-w-[165px];
        img {
            border-radius: 30px;
            -webkit-border-radius: 30px;
            -moz-border-radius: 30px;
            -ms-border-radius: 30px;
            -o-border-radius: 30px;
            @apply max-md:rounded-[20px];
            width: 100%;
            max-width: 100%;
        }
    }
}

:focus-visible {
    outline: none;
}