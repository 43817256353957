.product_item {
    padding: 0 .5rem;

    .product {
        background-color: #FAFAFA;
        padding: 10px;
        border-radius: 30px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -ms-border-radius: 30px;
        -o-border-radius: 30px;
        position: relative;
        cursor: pointer;
        @apply max-md:rounded-2xl;

        .top_section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 6px 0;
            width: 100%;
            max-width: calc(100% - 30px);
            margin: 0 auto;
            position: absolute;
            top: 5px;
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            z-index: 99;

            .discount_label {
                display: inline-block;
                font-size: 12px;
                background-color: #FF0000;
                color: #fff;
                padding: 2px 8px;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                min-width: 64px;
            }

            .wishlist {
                img {
                    width: 24px;
                    filter: brightness(0);
                    @apply max-md:w-4;
                }
            }

        }

        .product_image {
            border-radius: 15px;
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            -ms-border-radius: 15px;
            -o-border-radius: 15px;
            @apply overflow-hidden relative pb-[100%];

            img {
                @apply absolute left-0 top-0 h-full w-full object-cover;
            }
        }

        .product_detail {
            margin-top: 15px;

            .speedy {
                display: flex;
                align-items: center;
                justify-content: end;
            }

            .product_name {
                font-size: 13px;
                font-weight: 500;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                @apply h-[43px] text-ellipsis overflow-hidden;
            }

            .product_price_cart {
                display: flex;
                justify-content: space-between;
                align-items: center;
                @apply md:h-[48px] max-md:h-[32px];

                .actions_wrapper {
                    .addtocart {
                        background-color: #575756;
                        border: 1px solid #575756;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 37px;
                        width: 37px;
                        @apply rounded-full w-8 h-8;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .product_item {
        .product {
            .product_detail {
                .product_price_cart {
                    .price {
                        @apply text-xs;
                    }
                }
            }
        }
    }
}


table.x_wrapper {
    direction: rtl;
}

.x_email-items thead tr th {
    padding: 10px !important;
    text-align: right !important;
}

.x_email-items tbody tr td {
    padding: 10px !important;
}

.rps_7693 .x_order-totals th {
    text-align: right;
    padding: 0 0 6px !important;
}

.rps_7693 .x_item-qty {
    text-align: center !important;
}

.x_order-totals tr th {
    text-align: right !important;
    padding: 0 8px 8px !important;
}

.x_ordermailbgcolor td {
    padding: 8px;
}