$bg-clr: #EFEFEF;

* {
}

.Footer {
    @apply bg-white md:mt-[70px] mt-9;

    > * {
        @apply hidden md:block;
    }
}

.footer_top {
    background-color: #EFEFEF;
    @apply py-5 bg-btn-color bg-opacity-25;
}

.newsletter .title strong {
    display: block;
    font-size: 18px;
}

.newsletter .title span {
    font-size: 15px;
    font-weight: 300;
}

.newsletter form {
    display: flex;
    margin-top: 20px;
}

.newsletter form .input_field {
    width: 100%;
    max-width: 440px;
    margin-bottom: 0 !important;
}

.newsletter form .form-control {
    height: 54px;
    border-radius: 38px;
    -webkit-border-radius: 38px;
    -moz-border-radius: 38px;
    -ms-border-radius: 38px;
    -o-border-radius: 38px;
    padding: 0 20px;
}

.newsletter form .btn {
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.newsletter_message {
    font-size: 11px;
    font-weight: 300;
    margin-top: 35px;
}

.download_app {
    text-align: right;
}

.download_app ul {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.download_app .title {
    font-size: 21px;
}

.download_app .desc {
    font-size: 21px;
    font-weight: 300;
}

.footer_middle {
    padding: 40px 0;
}

.footer_middle .container > ul {
    display: flex;
}
.footer_middle .footer_item_1,
.footer_middle .footer_item_2 {
    @apply px-2;
}

.footer_middle .footer_item_1 ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-width: 160px;
}

.footer_middle .footer_item_1 ul > li {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 300;
}

.footer_middle .footer_item_1 ul > li img {
    filter: brightness(0);
    -webkit-filter: brightness(0);
}

.footer_middle .footer_item_1 ul > li a {
    color: #000;
    text-decoration: none;
    margin-left: 4px;
    font-size: 18px;
    font-weight: bold;
}

.footer_middle .footer_item_2 ul > li a {
    color: #000;
    font-size: 13px;
    font-weight: 300;
    text-decoration: none;

}